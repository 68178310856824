<!-- <template>
    <div class="container-fluid p-3 matrix-wrapper">
        <cc-loader v-if="loading || is_loading" />
        <table v-if="!loading && !is_loading && products" class="table">
            <thead>
                <tr>
                    <th colspan="4"></th>
                    <th v-for="store in stores" :key="store.loj_id" class="text-center store-quantity" @click="goto(store, total_store(store))">
                        <span class="description"><i class="fas fa-store-alt" style="color: #6081e8;font-size: 1rem;"></i>
                        {{ store.loj_descricao }}</span>
                    </th>
                </tr>
                <tr>
                    <th class="text-center pro-col" colspan="4">Produto</th>
                    <th class="text-center" v-for="store in stores" :key="'tot-' + store.loj_id">
                        <span class="description store-total"
                            :class="{ cursor: total_store(store) > 0, higher: total_store(store) > 0 && total_store(store) < min_fat }">
                                {{ total_store(store).toFixed(2) | currency }}
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(product, idy) in products">
                    <tr :key="'name-' + product.pro_id">
                        <td colspan="4" class="text-left border-bottom-0 pl-0">
                            <div class="col">
                                <p class="mb-0 pro-name" style="position:relative">
                                    {{ product.pro_principal_nome || product.pro_descricao }}
                                    <span class="brand">{{ product.pro_observacao }}</span>
                                    <i v-if="product.observacao" class="fas fa-info-circle" :title="product.observacao.toUpperCase()"></i>
                                </p>
                            </div>
                        </td>
                        <td v-for="(store, idx) in stores" :key="store.loj_id" rowspan="2" >
                            <div class="column-quantity">
                                <input type="text" class="rounded form-control text-center m-auto"
                                    :class="get_has_stock(products, store) ? 'store-quantity-reduce' : 'store-quantity'"
                                    :value="get_quantity(product.stores, store)"
                                    @keyup="update_quantity($event, filter_product(product.stores, store.loj_id))"
                                    @blur="handle_navigation($event, filter_product(product.stores, store.loj_id), idy, idx, store)"
                                    @keydown="handle_navigation($event, filter_product(product.stores, store.loj_id), idy, idx, store)"
                                    :data-idx="idy"
                                    :readonly="store.disabled"
                                    :data-idy="idx"
                                    :ref="'y-' + idy + '-' + idx"><span class="column-stock">{{get_stock_label(product.stores, store)}}</span>
                                <div style="position: relative">
                                    <cc-loader v-show="filter_product(product.stores, store.loj_id).loading == true" class="quantity-loader" :show_txt="false"></cc-loader>
                                </div>
                            </div>

                        </td>
                    </tr>
                    <tr :key="product.pro_id">
                        <td class="text-center border-top-0 pt-0" colspan="4">
                            <div class="col d-flex p-0">
                                <p class="mb-0 info">
                                    <span class="description">
                                        Vlr:
                                    </span>
                                    <span class="d-block text-center">
                                        <span :class="{ lower: product.valor < product.preco_ultima_compra, higher: product.valor > product.preco_ultima_compra, 'total-val': true }">
                                            {{ product.valor | currency }}
                                        </span>
                                        <i v-if="!has_orders()" class="fa fa-handshake-o" title="Negociar" @click="open_negotiation_modal(product)"></i>
                                    </span>
                                </p>
                                <p class="mb-0 info" title="ultimo preço">
                                    <span class="description">Ult Cmp:</span>
                                    <span class="d-block text-center">{{ product.preco_ultima_compra | currency }}</span>
                                </p>
                                <p class="mb-0 info">
                                    <span class="description">Qtd:</span>
                                    <span class="d-block text-center">{{ quantity_sum(product.stores) }}</span>
                                </p>
                                <p class="mb-0 info">
                                    <span class="description">Emb:</span>
                                    <span class="d-block text-center">
                                        {{ product.pro_unidade_compra ? product.pro_unidade_compra : `${product.pro_embalagem.split(" ")[0]} ${product.pro_embalagem.split(" ")[1]}` }}
                                        <i class="far fa-edit" @click="edit_wrap(product)" v-if="!has_orders() && product.pro_generico == 0 && !product.pro_principal && provider"/>
                                    </span>
                                </p>
                                <p class="mb-0 info">
                                    <span class="description">Total:</span>
                                    <span class="d-block text-center total-val">{{ total(product.stores) | currency }}</span>
                                </p>
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
        <NegotiationModal
            @close="reset_negotitation"
            :typing="{
                idVendedor: seller_id, idFornecedor: provider.idFornecedor,
                valor: current_product.valor, nomeVendedor: provider.nomeVendedor,
                pro_id: current_product.pro_id
            }"
            :product="{
                produtoDescricao: current_product.pro_descricao, pro_observacao: current_product.pro_observacao,
                observacao: current_product.observacao, ultimaCompra: current_product.preco_ultima_compra
            }"
            v-if="current_product && !open_edit_wrap"
            :quotation_id="id" />
        <ProductWrapModal
            v-if="open_edit_wrap && current_product"
            :product="{ ...current_product, descricaoProduto: current_product.pro_descricao, embalagem: current_product.pro_embalagem, idProduto: current_product.pro_id }"
            @close="current_product = null;open_edit_wrap = false"
            @reload="load_matrix"
        />
    </div>
</template>
<script>

import RequestsService from '@/services/v2/requests.service'
import { elementNavigationmMixin } from '@/mixins/navigation.mixin';
import QuotationService from "@/services/QuotationService";
import NegotiationModal from '@/modals/negotiate-offer/negotiate-offer.modal'
import ProductWrapModal from '@/modals/product-wrap/product-wrap.modal'
import { mapState } from 'vuex'

export default {
    components: { NegotiationModal, ProductWrapModal },
    mixins: [ elementNavigationmMixin ],
    props: {
        id: {
            type: [Number, String],
            required: true
        },
        seller_id: {
            type: [Number, String],
            required: true
        },
        provider: {
            type: [ Object ],
            required: true
        },
        min_fat: [ Number ],
        reload: [ Boolean ],
        is_loading: [ Boolean ],
        store: {
            type: Object
        }
    },
    data() {
        return {
            service: new QuotationService(),
            svc: new RequestsService(),
            products: null,
            current_product: null,
            open_edit_wrap: false,
            stores: [],
            loading: false
        }
    },
    methods: {
        edit_wrap(product) {
            this.current_product = product
            this.open_edit_wrap = true
        },
        filter_product(stores, store_id) {
            let result = stores.find(s => s.loj_id == store_id)
            if(!result) {
                result = {
                    quantidade: null,
                    loj_id: store_id,
                    pro_id: stores[0].pro_id,
                    valor: stores[0].valor,
                    pro_generico: stores[0].pro_generico,
                    pro_principal: stores[0].pro_principal,
                    pro_embalagem: stores[0].pro_embalagem
                }
                stores.push(result)
            }
            return result;
        },
        get_quantity(stores, store) {
            let result = this.filter_product(stores, store.loj_id)
            return result ? result.quantidade : null
        },
        get_stock(stores, store) {
            let result = this.filter_product(stores, store.loj_id)
            return result ? result.estoque : null
        },
        get_stock_label(stores, store) {
            let result = this.filter_product(stores, store.loj_id);
            return result.estoque ? " - ("+result.estoque+")" : null
        },
        quantity_sum(stores) {
            return stores.reduce((sum, store) => { return sum + (store.quantidade || 0) }, 0)
        },
        total_store(store) {
            let result = 0
            this.products.forEach(p => {
                p.stores.forEach(pp => {
                    if(pp.loj_id == store.loj_id) {
                        let value = pp.pro_generico == 0 && pp.pro_principal == null && this.user.request_type != 1 ? parseInt(pp.pro_embalagem.split(' ')[1])*pp.valor : pp.valor
                        result += value*pp.quantidade
                    }
                })
            })
            return result
        },
        total(stores) {
            return stores.reduce((sum, store) => { return sum + ((store.quantidade || 0)*(store.pro_generico == 0 && store.pro_principal == null && this.user.request_type != 1 ? parseInt(store.pro_embalagem.split(' ')[1])*store.valor : store.valor)) }, 0)
        },
        load_matrix(show_loader = true) {
            if(show_loader)this.loading = true
            this.svc.request_products_matrix(this.id, { seller_id: this.seller_id }).then(response => response.data)
            .then(data => {
                this.products = data;

                this.products.forEach(p => {
                    p.stores.forEach(pp => pp.initial_quantity = pp.quantidade)
                });
                this.loading = false
            }).catch(e => {
                this.loading = false
            })
        },
        update_quantity($event, prod) {
            let value = $event.target.value;
            prod.quantidade = (!value || isNaN(value.replace(",","."))) ? null : this.format_value_amount(value)
        },
        handle_navigation($event, product, x, y, store) {
            this.navigatrix($event, {
				callback: {
					function: product.quantidade != product.initial_quantity ? this.save : null,
                    params: product
				},
                x: x,
                y: y
			})
        },
        save(store, callback) {

            this.$set(store, 'loading', true);
            return this.service.sendQuantidade({
                numerocotacao: this.$route.params.id,
                quantidade: store.quantidade || "0",
                vendedor: this.seller_id,
                produto: store.pro_id,
                loja: store.loj_id
            })
            .then(response => response.data)
            .then(() => {
                store.initial_quantity = store.quantidade
                this.$set(store, 'loading', false)
            })
            .catch(e => {
                const notification = {
                    type: 'error',
                    message: 'Ocorreu um erro ao atualizar a quantidade.'
                }
                this.$store.dispatch('notification/add', notification)
            })
        },
        process_available_stores() {
            this.stores.forEach(store => store.disabled = false)
            this.svc.request_orders_by_seller(this.id, this.seller_id).then(response => response.data).then(data => {
                data.forEach(order => {
                    this.stores.forEach(store => {
                        if(store.loj_id == order.id_loja) {
                            store.disabled = true
                        }
                    })
                })
                this.$forceUpdate();
            })
        },
        sort_stores() {
            this.stores.sort((a, b) => {
				if (a.loj_numeroLoja > b.loj_numeroLoja) return 1
				if (b.loj_numeroLoja > a.loj_numeroLoja) return -1
				return 0
			})
        },
        goto(store, fat) {
            if(fat > 0) {
                this.$router.push({
                    name: 'lancamento-cliente-monitores-vencedores',
                    params: {
                        id: this.id,
                        sellerid: this.seller_id,
                        sid: store.loj_id,
                        providerid: this.$route.params.providerid
                    },
                    query: {
                        cli_id: this.$route.query.cli_id
                    }
                })
                this.$emit('update', 'TYPINGS')
            }
        },
        open_negotiation_modal(product) {
            this.current_product = product;
        },
        has_orders() {
            let result = false
            this.products.forEach(p =>
                p.stores.forEach(pp => {
                    if(p.id_pedido || pp.id_pedido) {
                        result = true
                    }
                })
            )
            return result
        },
        reset_negotitation(with_reload = false, new_value) {
            if(with_reload) {
                this.current_product.valor = new_value
            }
            this.current_product = null;
        },
        formatLength(e){
            return String(e).substring(0,4);
        },
        format_value_amount(amount){
            amount = amount.replace(",",".");
            var regex = new RegExp("[0-9].[5]");
            return (regex.test(amount))? parseFloat(amount): parseInt(amount);
        },
        get_has_stock(products,store){
            let result = false;
            products.forEach( product =>{
                for (let j = 0; j < product.stores.length; j++) {
                    if((product.stores[j].loj_id == store.loj_id) && product.stores[j].estoque){
                        result = true;
                        break;
                    }
                }
            })

            return result;
        }
    },
    watch: {
        reload: async function(newVal, oldVal) {
            this.load_matrix()
            this.process_available_stores()
        }
    },
    computed: {
        ...mapState([ 'user' ])
    },
    async mounted() {
        // this.stores = await this.$store.dispatch('user/fetch_stores', this.$route.query.cli_id)
        let resp =  await this.service.getLojasFornecedores(this.id)
        this.stores = resp.data.vendedores
        this.sort_stores()
        this.load_matrix()
        this.process_available_stores()
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_variables';
    .pro-name {
        font-weight: bold;
        font-size: 1rem;
        .brand {
            color: $secondary-color;
        }
    }
    .info {
        width: 20%;
        margin-left: 10px;
    }

    th.store-quantity {
        line-break: anywhere;
        white-space: revert;
        cursor: pointer;
        &:hover {
            background-color: $secondary-color;
            span, i {
                color: white !important;
            }
        }
    }
    th.store-quantity {
        min-width: 130px;
    }
    input.store-quantity {
        width: 100%;
    }

    input.store-quantity-reduce {
        width: 60%;
    }

    .total {
        border-right: 1px dashed #ece9e9;
    }

    .total-val {
        white-space: nowrap;
    }

    span.description {
        font-size: 0.8rem;
        white-space: nowrap;
        text-transform: uppercase;
        margin-right: 4px;
        margin-left: 2px;
        display: inline-block;
        text-align: center;
        color: $gray2;
    }

    tr:hover {
        td {
            background-color: white;
        }
    }

    .quantity-loader {
        position: absolute;
        width: 25%;
        top: -47px;
        left: 83%;
        height: fit-content !important;
    }

    .lower {
        background-color: $higher-green;
        border-radius: 6px;
        color: white;
        font-weight: 600;
        opacity: 0.6;
        padding: 0.2rem;
    }

    .higher {
        color: white !important;
        border-radius: 6px;
        background-color: $lower-red;
        font-weight: 600;
        opacity: 0.6;
        padding: 0.2rem;
    }

    .fa-store-alt {
        margin-right: 4px;
    }

    .fa-info-circle {
        color: $secondary-color;
        cursor: pointer;
    }

    .cursor {
        cursor: pointer;
    }

    .description.store-total {
        text-align: center;
        color: $ternary-color;
    }

    .obs {
        color: $secondary-color;
    }

    i.fa-handshake-o {
        width: 10px;
        padding-left: 4px;
        color: $orange;
        cursor: pointer;
    }

    .fa-edit {
        margin-left: 10px;
        color: $primary-color;
        cursor: pointer;
    }

    .matrix-wrapper {
        height: 60vh;
        overflow: auto;
    }

    @media print {
        .matrix-wrapper {
            height: 100% !important;
            overflow: visible !important;
        }
    }

    .pro-col {
        min-width: 600px;
    }

    .column-quantity{
        display: flex;
        justify-content: flex-start;
    }
    .column-stock{
        width: 45px;
        margin-left: 4px;
        display: flex;
        align-items: center;
    }

</style> -->


<template>
    <div>
        <!-- <PrintMatrixComponent
            :products="products"
            v-if="is_printing"
            :min_fat="min_fat"
            :provider="provider"
            :buyer="buyer" /> -->
        <div class="p-3 matrix-wrapper">
            <cc-loader v-if="loading || is_loading" />
            <div v-if="!loading && !is_loading && products" class="container-fluid">
                <div class="header" :style="{ width: `${33 + (stores.length * field_size)}rem` }">
                    <div>
                        <div class="col text-center pro-col">
                            <div class="products-header">
                                    <span >
                                        Vlr:
                                    </span>
                                    <span >Ult Cmp:</span>
                                    <span >Qtd:</span>
                                    <span >Emb:</span>
                                    <span >Total:</span>
                            </div>
                        </div>
                        <div v-for="store in stores" :key="store.id" class="col text-center quantity-header" @click="goto(store, store.total)">
                            <span class="store-header-item" :title="store.loj_descricao">
                                <span class="material-icons-outlined store-header-icon">store</span>
                                {{ store.loj_descricao }}
                            </span>
                        </div>
                    </div>
                    <div>
                        <div class="col text-center pro-col"></div>

                        <div class="col text-center quantity-header" v-for="store in stores" :key="'tot-' + store.loj_id">
                            <span class="description store-total"
                                :class="{ cursor: store.total > 0, higher: store.total > 0 && store.total < min_fat }">
                                    {{ store.total.toFixed(2) | currency }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="body" v-if="stores && stores.length > 0" :style="{ width: `${33 + (stores.length * field_size)}rem` }">
                    <template v-for="(item, index) in products">
                        <div :key="'name-' + item.pro_id" :class="getSpecialBackground(index)" class="prod-row">
                            <div class="text-left border-bottom-0 pl-0 prod-info">
                                <div>
                                        <div class="pro-name">
                                            {{ item.pro_principal_nome || item.pro_descricao }}
                                            <i v-if="item.observacao" class="fas fa-info-circle" style="color: #FF7110; margin-left: 1vw" :title="item.observacao.toUpperCase()"></i>
                                        </div>
                                        <div class="brand">{{ item.pro_observacao }}</div>
                                    <div class="text-center border-top-0 pt-0">
                                        <div class="d-flex p-0">
                                            <p class="mb-0 info">
                                                <span class="d-block text-center">
                                                    <span :class="{ lower: item.valor < item.preco_ultima_compra, higher2: item.valor > item.preco_ultima_compra, 'total-val': true }">
                                                        {{ item.valor | currency }}
                                                    </span>
                                                    <i v-if="!has_orders" class="fa fa-handshake-o" title="Negociar" @click="open_negotiation_modal(item)"></i>
                                                </span>
                                            </p>
                                            <p class="mb-0 info" title="ultimo preço">
                                                <span class="d-block text-center">{{ item.preco_ultima_compra | currency }}</span>
                                            </p>
                                            <p class="mb-0 info">
                                                <span class="d-block text-center">{{ quantity_sum(item.stores) }}</span>
                                            </p>
                                            <p class="mb-0 info">
                                                <span class="d-block text-center">
                                                    {{ item.pro_unidade_compra ? item.pro_unidade_compra : `${item.pro_embalagem.split(" ")[0]} ${item.pro_embalagem.split(" ")[1]}` }}
                                                    <i class="far fa-edit" @click="edit_wrap(item)" v-if="!has_orders && item.pro_generico == 0 && !item.pro_principal && provider"/>
                                                </span>
                                            </p>
                                            <p class="mb-0 info">
                                                <span class="d-block text-center total-val">{{ item.total | currency }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-for="(store, idx) in item.stores" :key="store.loj_id" class="quantity-wrapper">
                                <div class="column-quantity">
                                    <input type="text" style="border-radius: 8px;" class=" form-control text-center m-auto"
                                        :class="{
                                            'store-quantity-reduce' : store.has_stock,
                                            'store-quantity' : store.has_stock,
                                            'saved' : store.saved
                                        }"
                                        v-model.number="store.quantidade"
                                        @blur="handle_navigation($event, store, index, idx, item)"
                                        @keyup="handle_navigation($event, store, index, idx, item)"
                                        :id="'ipt-' + index + '-' + idx"
                                        :data-idy="index"
                                        :readonly="store.disabled"
                                        :data-idx="idx"
                                        :ref="'y-' + index + '-' + idx"><span class="column-stock">{{get_stock_label(store)}}</span>
                                    <div style="position: relative">
                                        <cc-loader v-show="store.loading" class="quantity-loader" :show_txt="false"></cc-loader>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <NegotiationModal
                @close="reset_negotitation"
                :typing="{
                    idVendedor: seller_id, idFornecedor: provider.idFornecedor,
                    valor: current_product.valor, nomeVendedor: provider.nomeVendedor,
                    pro_id: current_product.pro_id
                }"
                :product="{
                    produtoDescricao: current_product.pro_descricao, pro_observacao: current_product.pro_observacao,
                    observacao: current_product.observacao, ultimaCompra: current_product.preco_ultima_compra
                }"
                v-if="current_product && !open_edit_wrap"
                :quotation_id="id" />
            <ProductWrapModal
                v-if="open_edit_wrap && current_product"
                :product="{ ...current_product, descricaoProduto: current_product.pro_descricao, embalagem: current_product.pro_embalagem, idProduto: current_product.pro_id }"
                @close="close_wrap"
                @reload="load_matrix"
            />
        </div>
    </div>
</template>
<script>

import RequestsService from '@/services/v2/requests.service'
import { elementNavigationmMixin } from '@/mixins/navigation.mixin';
import QuotationService from "@/services/QuotationService";
import NegotiationModal from '@/modals/negotiate-offer/negotiate-offer.modal'
import ProductWrapModal from '@/modals/product-wrap/product-wrap.modal'
import { mapState } from 'vuex'
// import PrintMatrixComponent from './_print-products-matrix.component.vue'

export default {
    components: { NegotiationModal, ProductWrapModal },
    mixins: [ elementNavigationmMixin ],
    props: {
        // buyer: {
        //     type: Object
        // },
        id: {
            type: [Number, String],
            required: true
        },
        seller_id: {
            type: [Number, String],
            required: true
        },
        provider: {
            type: [ Object ],
            required: true
        },
        min_fat: [ Number ],
        reload: [ Boolean ],
        is_loading: [ Boolean ],
        is_printing: [ Boolean ]
    },
    data() {
        return {
            service: new QuotationService(),
            svc: new RequestsService(),
            products: null,
            current_product: null,
            open_edit_wrap: false,
            stores: [],
            loading: false,
            field_size: 8,
            call_qtd: 0,
            has_orders: false
        }
    },
    methods: {
        getSpecialBackground (index) {
            if (index % 2 != 0) return 'page-table-line-special'
        },
        close_wrap({ wrap }) {
            if(wrap) {
                this.current_product.pro_embalagem = wrap
            }
            this.current_product = null;
            this.open_edit_wrap = false
        },
        edit_wrap(product) {
            this.current_product = product
            this.open_edit_wrap = true
        },
        filter_product(stores, store) {
            let result = stores.find(s => s.loj_id == store.id)
            if(!result) {
                result = {
                    quantidade: null,
                    loading: false,
                    loj_id: store.id,
                    pro_id: stores[0].pro_id,
                    saved: false,
                    valor: stores[0].valor,
                    pro_generico: stores[0].pro_generico,
                    pro_principal: stores[0].pro_principal,
                    pro_embalagem: stores[0].pro_embalagem
                }
            }
            return { ...result, loading: false, saved: false, disabled: store.disabled };
        },
        get_quantity(store) {
            return store ? store.quantidade : null
        },
        get_stock(store) {
            return store ? store.estoque : null
        },
        get_stock_label(store) {
            return store.estoque ? "("+store.estoque+")" : null
        },
        quantity_sum(stores) {
            return stores.reduce((sum, store) => { return sum + (store.quantidade || 0) }, 0)
        },
        total_store(store) {
            let result = 0
            this.products.forEach(p => {
                p.stores.forEach(pp => {
                    if(pp.loj_id == store.id) {
                        let value = pp.pro_generico == 0 && pp.pro_principal == null && this.user.request_type != 1 ? parseInt(pp.pro_embalagem.split(' ')[1])*pp.valor : pp.valor
                        result += value*pp.quantidade
                    }
                })
            })
            store.total = result
        },
        total(item) {
            item.total = item.stores.reduce((sum, store) => { return sum + ((store.quantidade || 0)*(store.pro_generico == 0 && store.pro_principal == null && this.user.request_type != 1 ? parseInt(store.pro_embalagem.split(' ')[1])*store.valor : store.valor)) }, 0)
        },
        load_matrix(show_loader = true) {
            if(show_loader)this.loading = true
            return this.svc.request_products_matrix(this.id, { seller_id: this.seller_id }).then(response => response.data)
            .then(data => {
                data.forEach(p => {
                    let prod_stores = p.stores
                    p.stores.forEach(pp => pp.initial_quantity = pp.quantidade)
                    p.stores = []
                    this.stores.forEach(s => {
                        let curr = this.filter_product(prod_stores, s)
                        prod_stores.forEach(ps => ps.initial_quantity = ps.quantidade)
                        curr.disabled = s.disabled
                        p.stores.push(curr)
                    })
                });
                this.products = data;
                this.products.forEach(p => this.total(p))
                this.stores.forEach(s => this.total_store(s))
                this.load_has_orders()
                this.products.forEach(p => this.load_has_stock(p))
                this.loading = false
            }).catch(e => {
                this.loading = false
            })
        },
        handle_navigation($event, product, x, y, item) {
            this.navigatrix($event, {
				callback: {
					function: product.quantidade != product.initial_quantity ? this.save : null,
                    params: { product, item }
				},
                x: x,
                y: y
			})
        },
        save({ product, item }, callback) {
            let store = product
            store.loading = true
            this.$set(store, 'loading', true);
            return this.service.sendQuantidade({
                numerocotacao: this.$route.params.id,
                quantidade: store.quantidade || "0",
                vendedor: this.seller_id,
                produto: store.pro_id,
                loja: store.loj_id
            })
            .then(response => response.data)
            .then(() => {
                store.initial_quantity = store.quantidade
                this.$set(store, 'loading', false)
                this.$set(store, 'saved', true);
                this.total_store(this.stores.find(s => s.id == product.loj_id))
                this.total(item)
            })
            .catch(e => {
                const notification = {
                    type: 'error',
                    message: 'Ocorreu um erro ao atualizar a quantidade.'
                }
                this.$store.dispatch('notification/add', notification)
            })
        },
        process_available_stores() {
            this.stores.forEach(store => store.disabled = false)
            this.svc.request_orders_by_seller(this.id, this.seller_id).then(response => response.data).then(data => {
                data.forEach(order => {
                    this.products.forEach(p => {
                        p.stores.forEach(store => {
                            if(store.loj_id == order.id_loja) {
                                store.disabled = true
                            }
                        })
                    })
                })
                this.$forceUpdate();
            })
        },
        sort_stores() {
            this.stores.sort((a, b) => {
                a.total = 0
				if (a.position > b.position) return 1
				if (b.position > a.position) return -1
				return 0
			})
        },
        goto(store, fat) {
            if(fat > 0) {
                this.$router.push({ name: 'lancamento-cliente-monitores-vencedores', params: { id: this.id, sellerid: this.seller_id, sid: store.id, providerid: this.$route.params.providerid } })
                this.$emit('update', 'TYPINGS')
            }
        },
        open_negotiation_modal(product) {
            this.current_product = product;
        },
        load_has_orders() {
            let result = false
            this.products.forEach(p =>
                p.stores.forEach(pp => {
                    if(p.id_pedido || pp.id_pedido) {
                        result = true
                    }
                })
            )
            this.has_orders = result
        },
        reset_negotitation(with_reload = false, new_value) {
            if(with_reload) {
                this.current_product.valor = new_value
            }
            this.current_product = null;
        },
        formatLength(e){
            return String(e).substring(0,4);
        },
        format_value_amount(amount){
            amount = amount.replace(",",".");
            var regex = new RegExp("[0-9].[5]");
            return (regex.test(amount))? parseFloat(amount): parseInt(amount);
        },
        load_has_stock(products, store){
            let result = false;
            products.forEach( product =>{
                for (let j = 0; j < product.stores.length; j++) {
                    if((product.stores[j].loj_id == store.id) && product.stores[j].estoque){
                        result = true;
                        break;
                    }
                }
            })
            store.has_stock = result
            return result;
        }
    },
    watch: {
        reload: async function(newVal, oldVal) {
            this.load_matrix().then(this.process_available_stores)
        }
    },
    computed: {
        ...mapState([ 'user' ])
    },
    async mounted() {
        // this.stores = await this.$store.dispatch('user/fetch_stores', this.id)
        let resp =  await this.service.getLojasFornecedores(this.id)
        this.stores = resp.data.vendedores
        this.sort_stores()
        await this.load_matrix().then(this.process_available_stores)
        this.$forceUpdate()
    }
}
</script>

<style lang="scss"  scoped>
.products-header{
    display: flex;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 400;
    font-size: 16px;
    color: #605F5F;
    justify-content: space-between;
    align-items: center;
    padding: 1vh 2vw;
}
.store-header-icon{color: #FF7110;}
.store-header-item{
    font-size: 0.9rem;
    font-weight: bolder;
    white-space: nowrap;
    text-transform: uppercase;
    margin-right: 4px;
    margin-left: 2px;
    display: inline-block;
    text-align: center;
    color: gray;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 7rem;
    border: 1px solid #FF7110;
    border-radius: 8px;
    padding: 0.5vh 1vw;
    cursor: pointer;
}
.page-table-line-special{
    background: #F7F7F7;
}
::-webkit-scrollbar{
        height: 1vh;
        width: 0;
        background: gray;
    }
    ::-webkit-scrollbar-thumb:horizontal{
        background: #FF7110;
        border-radius: 10px;
    }
    @import '@/sass/commons/_variables';

$first-col-width: 33rem;
$cell-width: 8rem;

.prod-row {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    
    margin: 0 0;
    padding-left: 1.2rem;
    padding-bottom: 10px;
    border-left: 1px solid white;
    &:hover {
        border-left: 1px solid $primary-color;
    }
    &:first-child {
        padding-top: 0;
    }
}
.quantity-wrapper {
    align-self: end;
    max-width: $cell-width;
}

.scroller {
    height: 100%;
}

.matrix-wrapper {
    height: 100%;
    
}
.container-fluid {
    width: 100%;
    margin: 0;
    // overflow-y: hidden;
    .header {
        .pro-col {
            width: $first-col-width;
        }
        > div {
            display: flex;
        }
        
    }
    
}
.quantity-header {
    max-width: $cell-width;
}
.body {
    direction: rtl;
    /deep/ {
        .prod-row {
            direction: ltr;
            
            display: flex;
            flex-wrap: nowrap;
        }
    }
}
.prod-info {
    white-space: nowrap;
    margin-right: 15px;
    min-width: $first-col-width;
}
.pro-name {
    font-size: 1rem;
    color: #605F5F;
    font-weight: 600;
}
.brand {
    color: #FF7110;
    font-weight: 600;
    font-size: 0.8rem;
    margin-bottom: 1.5vh;
}
.header .info {
    margin-left: 25px;
}

.info {
    width: 20%;
    margin-left: 10px;
}

th.store-quantity {
    line-break: anywhere;
    white-space: revert;
    cursor: pointer;
    &:hover {
        background-color: $secondary-color;
        span, i {
            color: white !important;
        }
    }
}
th.store-quantity {
    min-width: 12rem;
}
input.store-quantity {
    width: 100%;
}

input.store-quantity-reduce {
    width: 60%;
}

.total {
    border-right: 1px dashed #ece9e9;
}

.total-val {
    white-space: nowrap;
}

.pro-col {
    .description {
        text-decoration: underline;
    }
}
span.description {
    // width: fit-content;
    font-size: 0.9rem;
    font-weight: bolder;
    white-space: nowrap;
    text-transform: uppercase;
    margin-right: 4px;
    margin-left: 2px;
    display: inline-block;
    text-align: center;
    color: $gray3;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 6rem;
}

tr:hover {
    td {
        background-color: white;
    }
}

.quantity-loader {
    position: absolute;
    width: 1.5rem;
    top: 5px;
    left: -1rem;
    padding: 0 !important;
    height: fit-content !important;
}

.lower {
    opacity: 0.7;
    background-color: green;
    color: white;
    border-radius: 6px;
    font-weight: 600;
    padding: 0.2rem;
}

.higher {
    border-radius: 6px;
    color: lightcoral !important;
    font-weight: 600;
    padding: 0.2rem;
}

.higher2 {
    border-radius: 6px;
    opacity: 0.8;
    background-color: lightcoral;
    color: white !important;
    font-weight: 600;
    padding: 0.2rem;
}

.fa-store-alt {
    margin-right: 4px;
}

.fa-info-circle {
    color: $secondary-color;
    cursor: pointer;
}

.cursor {
    cursor: pointer;
}

.description.store-total {
    text-align: center;
    color: $ternary-color;
}

.obs {
    color: $secondary-color;
}

i.fa-handshake-o {
    width: 10px;
    padding-left: 4px;
    color: $orange;
    cursor: pointer;
}

.fa-edit {
    margin-left: 10px;
    color: $primary-color;
    cursor: pointer;
}

.pro-col {
    max-width: $first-col-width;
    min-width: $first-col-width;
    font-weight: bolder;
    padding: 0;
    width: $first-col-width;
    margin-left: 1.2rem;
}

.column-quantity{
    display: flex;
    justify-content: center;
    input.saved {
        background-color: #13c7213d;
        color: green !important;
    }
}
.column-stock{
    width: 2.5rem;
    margin-left: 4px;
    display: flex;
    align-items: center;
}

</style>


