<template>
  <div :class="is100Percent ? 'modal-mask-100' : 'modal-mask'">
    <div :class="is100Percent ? 'modal-content-container-100' : 'modal-content-container'">
      <div class="new-product__header-container">
        <div style="display: flex; align-items: center; gap: 3vw;">
          <img v-if="product.pro_imagem" class="product-details-img" :src="product.pro_imagem">
          <img v-else class="product-details-img product-details-no-product" src="@/assets/newLogo.jpeg">
          <span class="new-product__header-title">
            {{ product.name }}
          </span>
        </div>

        <span @click="close()" class="material-icons new-product__closebtn-icon">
          cancel
        </span>
      </div>
      <div class="product-brands-tabs-container">
        <div class="product-brands-tab" :class="getActiveTab('brand')" v-on:click="tab = 'brand'">
          <span class="material-icons-outlined product-brands-tab-icon">sell</span>
          <div class="product-brands-tab-text">Marcas</div>
        </div>
        <div class="product-brands-tab" :class="getActiveTab('supliers')" v-on:click="tab = 'supliers'">
          <span class="material-icons-outlined product-brands-tab-icon">groups</span>
          <div class="product-brands-tab-text">Fornecedor</div>
        </div>
        <div class="product-brands-tab" :class="getActiveTab('price')" v-on:click="tab = 'price'">
          <span class="material-icons-outlined product-brands-tab-icon">currency_exchange</span>
          <div class="product-brands-tab-text">Preços</div>
        </div>
      </div>
      <ProductBrandTab v-if="tab == 'brand'" :product="product" :product_pattern="product_pattern" />
      <supliersBrandTab v-else-if="tab == 'supliers'" :productId="product.id" />
      <PriceBrandTab v-else :productId="product.id" :is100Percent="is100Percent"/>
    </div>
  </div>
</template>

<script>
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import ProductBrandTab from './product-brand-tab.vue'
import supliersBrandTab from './product-supliers-tab.vue'
import PriceBrandTab from './PriceBrandTab.vue';

export default {
  mixins: [loaderMixin],
  components: {
    ProductBrandTab,
    supliersBrandTab,
    PriceBrandTab
  },
  data() {
    return {
      tab: undefined,
      is100Percent: false
    }
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    product_pattern: {
      type: Boolean,
      required: true
    },
    initTab: {
      type: String,
      required: true
    }
  },
  created() {
    this.tab = this.initTab
  },
  watch: {
    tab(value) {
      if (value == 'price') {
        this.is100Percent = true
        document.body.style.zoom = '100%'
      } else {
        this.is100Percent = false
        document.body.style.zoom = '80%'
      }
    }
  },
  methods: {
    close() {
      document.body.style.zoom = '80%'
      this.$emit('close')
    },
    getActiveTab(tab) {
      if (tab == this.tab) return 'product-brands-tab-active'
    }
  },
}
</script>

<style lang="scss" scoped>
@import './product-brands.modal';

.modal-mask {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  font-size: 1vw;
}
.modal-mask-100 {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  font-size: 0.8vw;
  font-size: 0.8vw;
}
.modal-content-container-100 {
  background-color: white;
  width: 80vw;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.modal-content-container {
  background-color: white;
  width: 100vw;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.new-product__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5vh 2vw;
  border-radius: 10px 10px 0px 0px;
  background-color: #ff7110;
}

.new-product__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 2.6em;
}

.new-product__closebtn-icon {
  color: #ffffff;
  font-size: 4em;
  cursor: pointer;
}

.product-brands-tabs-container {
  margin: 2.5vh 5vw 0 5vw;
  display: flex;
  justify-content: space-between;
}

.product-details-img {
  max-width: 10vw;
  max-height: 10vh;
  border-radius: 40%;
}

.product-details-no-product {
  background-color: white;
  border-radius: 40%;
  padding: 0.5vw 1vw;
}

.product-brands-tab {
  padding: 1vh 0;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vw;
}

.product-brands-tab-icon {
  font-size: 2em;
  color: #ff7110;
}

.product-brands-tab-text {
  font-size: 1.5em;
}

.product-brands-tab:hover {
  background: rgba(255, 113, 16, 0.15);
  border-radius: 8px;
  cursor: pointer;
  color: #ff7110;
}

.product-brands-tab-active {
  background: rgba(255, 113, 16, 0.15);
  border-radius: 8px;
  cursor: pointer;
  color: #ff7110;
}

@media only screen and (max-width: 800px) {
  .modal-content-container {
    width: 100vw;
  }

  .modal-mask {
    font-size: 2vw;
  }
  .modal-mask-100 {
    font-size: 2vw;
}
.modal-content-container-100{
  width: 90vw;
}
}

@media only screen and (max-width: 450px) {
  .modal-content-container {
    width: 115vw;
  }

  .modal-mask {
    font-size: 3vw;
  }
  .modal-mask-100 {
    font-size: 3vw;
}
.modal-content-container-100{
  width: 95vw;
}
}</style>
